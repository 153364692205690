import React, { Component } from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import de from "react-intl/locale-data/de";
import TimelineComponent from "./Timeline";
import NavigationComponent from "./Navigation";
import HeaderComponent from "./Header";
import AboutMeComponent from "./AboutMe";
import PortfolioComponent from "./Portfolio";
import ContactComponent from "./Contact";
import FooterComponent from "./Footer";

import "./App.scss";

import translationsDE from "./translationsDE.json";
import translationsEN from "./translationsEN.json";

addLocaleData([...en, ...de]);

class App extends Component {
  constructor(props) {
    super(props);
    if (matchMedia("(prefers-color-scheme: dark)").matches) {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.setAttribute("data-theme", "light");
    }

    this.state = { locale: "en" };
  }
  render() {
    return (
      <IntlProvider
        locale={this.state.locale}
        messages={this.state.locale === "en" ? translationsEN : translationsDE}
      >
        <React.Fragment>
          <NavigationComponent locale={e => this.setState({ locale: e })} />
          <HeaderComponent />
          <AboutMeComponent />
          <TimelineComponent />
          <PortfolioComponent />
          <ContactComponent />
          <FooterComponent />
        </React.Fragment>
      </IntlProvider>
    );
  }
}

export default App;
