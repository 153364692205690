/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { configureAnchors } from "react-scrollable-anchor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon } from "@fortawesome/free-solid-svg-icons";
import cx from "classnames";
import SocialLinksComponent from "./SocialLinks";

import "./App.scss";

configureAnchors({
  offset: -120,
  scrollDuration: 1000,
  keepLastAnchorHash: false
});

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "en",
      darkColor: matchMedia("(prefers-color-scheme: dark)").matches
        ? true
        : false
    };

    this.onActive = this.onActive.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleColor = this.toggleColor.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    this.onClose();
  }

  toggleMenu() {
    const x = document.getElementById("nav-top-menu");
    if (x.style.display === "block") {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  toggleColor(value) {
    this.setState({ darkColor: value });
    if (value === true) {
      document.body.setAttribute("data-theme", "dark");
    } else {
      document.body.setAttribute("data-theme", "light");
    }
  }

  onClose() {
    const x = document.getElementById("nav-top-menu");
    if (document.body.clientWidth <= 768) {
      x.style.display = "none";
    } else {
      x.style.display = "block";
    }
  }

  onActive(value) {
    this.setState({ locale: value });
    this.props.locale(value);
  }

  render() {
    return (
      <div className="nav-top">
        <div className="nav-top-title">
          <div className="nav-top-name">Stefan Josef W.</div>
          <div className="nav-top-text">
            <mark>Front-End</mark> Engineer / <mark>JS</mark> Ninja /{" "}
            <mark>UX/UI</mark> Designer
          </div>
        </div>
        <div id="nav-top-menu">
          <ul>
            <li onClick={this.onClose}>
              <a href="#section1">
                <FormattedMessage id="nav.start" />
              </a>
            </li>
            <li onClick={this.onClose}>
              <a href="#section2">
                <FormattedMessage id="nav.aboutMe" />
              </a>
            </li>
            <li onClick={this.onClose}>
              <a href="#section3">
                <FormattedMessage id="nav.cv" />
              </a>
            </li>
            <li onClick={this.onClose}>
              <a href="#section4">
                <FormattedMessage id="nav.portfolio" />
              </a>
            </li>
            <li onClick={this.onClose}>
              <a href="#section5">
                <FormattedMessage id="nav.contact" />
              </a>
            </li>
            <li className="social-mobile">
              <SocialLinksComponent />
            </li>
            <li>
              <span
                className={cx("languages-switcher", {
                  active: this.state.locale === "en"
                })}
                onClick={() => {
                  this.onClose();
                  this.onActive("en");
                }}
              >
                EN
                {"/"}
              </span>
              <span
                className={cx("languages-switcher", {
                  active: this.state.locale === "de"
                })}
                onClick={() => {
                  this.onClose();
                  this.onActive("de");
                }}
              >
                DE
              </span>
            </li>

            <span
              className={cx({
                active: this.state.darkColor === false
              })}
              onClick={() => {
                this.onClose();
                this.toggleColor(!this.state.darkColor);
              }}
            >
              <FontAwesomeIcon icon={faMoon} />
            </span>
          </ul>
        </div>
        <a className="icon" onClick={this.toggleMenu}>
          <FontAwesomeIcon icon={faBars} />
        </a>
      </div>
    );
  }
}

export default Navigation;
