import React from "react";
import { FormattedMessage } from "react-intl";
import ScrollableAnchor from "react-scrollable-anchor";
import { useTransition, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
export const Timeline = () => {

  const [ref, inView] = useInView({ triggerOnce: true });
  const [ref3, inView3] = useInView({ triggerOnce: true });

  const transitions = useTransition(inView, null, {
    config: { duration: 1000 },
    from: { transform: "translate3d(-100px,0,-100px)", zIndex: "-10000" },
    enter: { transform: "translate3d(0,0px,-100px)", zIndex: "-10000" },
    leave: { transform: "translate3d(-100px,0,-100px)", zIndex: "-10000" }
  });

  const transitions3 = useTransition(inView3, null, {
    config: { duration: 3000 },
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });
  return (
    <ScrollableAnchor id={"section3"}>
      <div className="timeline">
        <span ref={ref}>
          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div
                  className="timeline-header"
                  key={key}
                  style={props}
                >
                  <h2>
                    <FormattedMessage id="timeline.header" />
                  </h2>
                </animated.div>
              )
          )}
          <div className="timeline-text cv">
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>Stefan Josef Wensauer</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="timeline.home" />
                  </td>
                  <td>Kempten (Allgäu)</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage id="timeline.birthday" />
                  </td>
                  <td>3. Mai 1985</td>
                </tr>
                <tr>
                  <td style={{ borderBottom: "none" }}>
                    <FormattedMessage id="timeline.standings" />
                  </td>
                  <td style={{ borderBottom: "none" }}>
                    <FormattedMessage id="timeline.single" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          
          <div ref={ref3} className="timeline-header sub exp">
            {transitions3.map(
              ({ item, key, props }) =>
                item && (
                  <animated.div key={key} style={props}>
                    <FormattedMessage id="timeline.experience" />
                  </animated.div>
                )
            )}
            <b />
          </div>
          <div className="timeline-text">
            <h3>Kasasi GmbH</h3>
            <h4>Kempten</h4>
            <p>
              <FormattedMessage id="timeline.frontendDev" />
            </p>
            <p>
              <FormattedMessage id="timeline.kasasi.first" /> <b>Dockers</b>{" "}
              <FormattedMessage id="timeline.and" /> <b>Microservices</b>
              . <FormattedMessage id="timeline.kasasi.second" />{" "}
              <mark>Javascript</mark>, <mark>HTML</mark>, <mark>SCSS</mark>,{" "}
              <mark>React</mark>, <mark>Redux</mark>, <mark>Babel</mark>,{" "}
              <mark>Webpack</mark> etc.
            </p>

            <br />
            <br />
            <br />

            <h3>
              <FormattedMessage id="timeline.internship" />
            </h3>
            <h4>Kempten</h4>
            <p>
              <FormattedMessage id="timeline.softwareDev" />
            </p>
            <p>
              <FormattedMessage id="timeline.internship.first" />
              <b>
                <FormattedMessage id="timeline.internship.graphics" />
              </b>
              <FormattedMessage id="timeline.internship.second" />
              <mark>
                <FormattedMessage id="timeline.internship.integration" />
              </mark>
              <FormattedMessage id="timeline.internship.third" />
            </p>

            <br />
            <br />
            <br />
            <br />

            <h3>
              <FormattedMessage id="timeline.uni" />
            </h3>
            <h4>Hagen</h4>
            <p>
              Master of Computer Science,{" "}
              <FormattedMessage id="timeline.aborted" />
            </p>

            <br />
            <br />
            <br />
            <br />

            <h3>
              <FormattedMessage id="timeline.hs" />
            </h3>
            <h4>Kempten(Allgäu)</h4>
            <p>Bachelor of Science, Computer Science - Game Engineering</p>

            <br />
            <br />
            <br />
            <br />

            <h3>
              <FormattedMessage id="timeline.college" />
            </h3>
            <h4>Memmingen</h4>
            <p>
              <FormattedMessage id="timeline.college.title" />
            </p>

            <br />
            <br />
            <br />
            <br />

            <h3>
              <FormattedMessage id="timeline.chamber" />
            </h3>
            <h4>Augsburg</h4>
            <p>
              <FormattedMessage id="timeline.chamber.title" />
            </p>
          </div>
        </span>
      </div>
    </ScrollableAnchor>
  );
};

export default Timeline;
