import React from "react";
import { FormattedMessage } from "react-intl";
import ScrollableAnchor from "react-scrollable-anchor";
import { useTransition, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

import npmIcon from "./icons/Npm.png";
import "./App.scss";

export const AboutMe = () => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const transitions = useTransition(inView, null, {
    config: { duration: 1000 },
    from: { transform: "translate3d(-100px,0,-100px)", zIndex: "-10000" },
    enter: { transform: "translate3d(0,0px,-100px)", zIndex: "-10000" },
    leave: { transform: "translate3d(-100px,0,-100px)", zIndex: "-10000" }
  });
  return (
    <ScrollableAnchor id={"section2"}>
      <div className='about'>
        <span ref={ref}>
          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div className='about-header' key={key} style={props}>
                  <FormattedMessage id='aboutMe.header.first' />{" "}
                  <b>
                    {" "}
                    <FormattedMessage id='aboutMe.header.second' />
                  </b>
                </animated.div>
              )
          )}
          <p className='about-text'>
            <FormattedMessage id='aboutMe.header.text1' />{" "}
            <mark>
              <FormattedMessage id='aboutMe.header.text2' />
            </mark>{" "}
            <FormattedMessage id='aboutMe.header.text3' />{" "}
            <mark>
              <FormattedMessage id='aboutMe.header.text4' />
            </mark>{" "}
            <FormattedMessage id='aboutMe.header.text5' /> <br />
            <br />
            <FormattedMessage id='aboutMe.header.text6' />{" "}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/facebook/create-react-app'
            >
              <img src={npmIcon} alt='npmicon' width={48} />
            </a>
            <br />
            <FormattedMessage id='aboutMe.header.text7' />{" "}
            <FormattedMessage id='aboutMe.header.text8' /> <br />
            <br />
            <FormattedMessage id='aboutMe.header.text9' />{" "}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/formatjs/react-intl'
            >
              <img src={npmIcon} alt='npmicon' width={48} />
            </a>
            <FormattedMessage id='aboutMe.header.text10' />{" "}
            <a
              target='_blank'
              rel='noopener noreferrer'
              href='https://github.com/gabergg/react-scrollable-anchor'
            >
              <img src={npmIcon} alt='npmicon' width={48} />
            </a>
          </p>
        </span>
      </div>
    </ScrollableAnchor>
  );
};

export default AboutMe;
