import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { FaHeart } from "react-icons/lib/fa";
import PortalComponent from "./portalComponent";
import Disclaimer from "./Disclaimer";
import SocialLinksComponent from "./SocialLinks";
import UberIcon from "./icons/icon-uberspace.png";

import "./App.scss";

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDisclaimerPortal: false
    };

    this.toggleWindowPortal = this.toggleWindowPortal.bind(this);
  }

  toggleWindowPortal() {
    this.setState(state => ({
      ...state,
      showDisclaimerPortal: !state.showDisclaimerPortal
    }));
  }

  render() {
    const { showDisclaimerPortal } = this.state;
    return (
      <div className='footer'>
        <SocialLinksComponent />
        <div className='footer-text'>
          <ul>
            <li>© Copyright 2021 Stefan J. Wensauer</li>
            <li>
              <FormattedMessage id='footer.hosted' />
              {"  "}
              <a
                title='Surge'
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.uberspace.de'
              >
                <img src={UberIcon} alt='ubericon' className={"uber-icon"} />
              </a>
            </li>
            <li>
              <FormattedMessage id='footer.developed' /> <FaHeart />
            </li>
            <li>
              <div
                className='footer-disclaimer'
                onClick={this.toggleWindowPortal}
              >
                Disclaimer
              </div>
            </li>
          </ul>
        </div>
        {showDisclaimerPortal && (
          <PortalComponent>
            <Disclaimer />
          </PortalComponent>
        )}
      </div>
    );
  }
}

export default Footer;
