import React from "react";
import { FormattedMessage } from "react-intl";
import ScrollableAnchor from "react-scrollable-anchor";
import { useTransition, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import HS from "./images/hs_kempten.jpg";
import Brutalsim from "./images/brutalism_sw.png";
import KasasiRedesign from "./images/kasasi_redesign.png";
import Medium from "./images/medium.png";

import "./App.scss";
import NikeApp from "./images/nikeApp.mp4";

export const Portfolio = () => {
  const [ref, inView] = useInView({ triggerOnce: true });

  const transitions = useTransition(inView, null, {
    config: { duration: 1000 },
    from: { transform: "translate3d(0,0,-100px)", zIndex: "-10000" },
    enter: { transform: "translate3d(0,0px,-100px)", zIndex: "-10000" },
    leave: { transform: "translate3d(0,0,-100px)", zIndex: "-10000" }
  });

  return (
    <ScrollableAnchor id={"section4"}>
      <div>
        <div ref={ref}>
          {transitions.map(
            ({ item, key, props }) =>
              item && (
                <animated.div key={key} style={props}>
                  <div className="portfolio-header">
                    <h2>Portfolio</h2>
                  </div>
                </animated.div>
              )
          )}
          <div className="portfolio">
            <div className="portfolio-container">
              <h4>Design</h4>
              <a
                href="https://drive.google.com/open?id=1LjGQSeHsgL2n3NJBX0M44l3EbXTC_C2d"
                target="_Blank"
                rel="noopener noreferrer"
                title=""
              >
                <img
                  className="portfolio-image"
                  alt=""
                  width="300"
                  src={Brutalsim}
                />
              </a>
              <p>
                <FormattedMessage id="portfolio.black" />
              </p>
            </div>

            <div className="portfolio-container">
              <h4>Design</h4>
              <video width="400" src={NikeApp} autoPlay loop />
              <p>
                <FormattedMessage id="portfolio.nikeApp" />
              </p>
            </div>

            <div className="portfolio-container">
              <h4>Design</h4>
              <a
                href="https://drive.google.com/file/d/18Yu5l2DO1aNzWp-huBaJn83y-So6i2bV"
                target="_Blank"
                rel="noopener noreferrer"
                title=""
              >
                <img
                  className="portfolio-image"
                  alt=""
                  width="300"
                  src={KasasiRedesign}
                />
              </a>
              <p>
                <FormattedMessage id="portfolio.kasasi" />
              </p>
            </div>

            <div className="portfolio-container">
              <h4>Bachelor Thesis</h4>
              <a
                href="https://drive.google.com/open?id=19OzkqQF7Ft_KWfpMTH1HxS4pbn-M--9b"
                target="_Blank"
                rel="noopener noreferrer"
                title=""
              >
                <img className="portfolio-image" alt="" width="300" src={HS} />
              </a>
              <p>
                <FormattedMessage id="portfolio.bachelor" />
              </p>
            </div>

            <div className="portfolio-container">
              <h4>Seminar</h4>
              <a
                href="https://drive.google.com/open?id=1UT51AdXhCSgASL1_VaAP8Ab3m2v0LxQK"
                target="_Blank"
                rel="noopener noreferrer"
                title=""
              >
                <img className="portfolio-image" alt="" width="300" src={HS} />
              </a>
              <p>
                <FormattedMessage id="portfolio.seminar" />
              </p>
            </div>
            <div className="portfolio-container">
              <h4>Medium</h4>
              <a
                href="https://medium.com/@stefanwensauer/internationalisierung-und-lokalisierung-mit-react-intl-b35670a1d08f?sk=1b6e11b41e99b918dc8c1c104eae03c0"
                target="_Blank"
                rel="noopener noreferrer"
                title=""
              >
                <img
                  className="portfolio-image"
                  alt=""
                  width="300"
                  src={Medium}
                />
              </a>
              <p>
                <FormattedMessage id="portfolio.medium1" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </ScrollableAnchor>
  );
};

export default Portfolio;
