import React from "react";
import { FormattedMessage } from "react-intl";
import ScrollableAnchor from "react-scrollable-anchor";
import { useTransition, animated } from "react-spring";
import Avatar from "./images/avatar2.jpeg";
import "./App.scss";

export const Header = () => {
  const transitions = useTransition(true, null, {
    config: { duration: 4000 },
    from: { opacity: 0 },
    enter: { opacity: 1.5 },
    leave: { opacity: 0 }
  });
  return (
    <ScrollableAnchor id={"section1"}>
      <div className="header">
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div className="header-text" key={key} style={props}>
                <FormattedMessage id="header.looking" />
                <em>
                  {" "}
                  <FormattedMessage id="header.for" />
                </em>{" "}
                <FormattedMessage id="header.myNext" />{" "}
                <b>
                  <FormattedMessage id="header.challenge" />
                </b>{" "}
                <em>
                  <FormattedMessage id="header.to" />
                </em>{" "}
                <b>
                  <FormattedMessage id="header.improve" />
                </b>{" "}
                <em>
                  <FormattedMessage id="header.your" />
                </em>{" "}
                <mark>
                  <FormattedMessage id="header.web" />
                </mark>{" "}
                <FormattedMessage id="header.experience" />
              </animated.div>
            )
        )}
        <img src={Avatar} alt="avatar" className={"avatar"} />
      </div>
    </ScrollableAnchor>
  );
};

export default Header;
