import React from "react";
import {
  FaFacebook,
  FaGithub,
  FaTwitter,
  FaXing,
  FaInstagram
} from "react-icons/lib/fa";

import "./App.scss";

const SocialLinks = () => (
  <ul className='social'>
    <li>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.instagram.com/stefan.wens/'
      >
        <FaInstagram />
      </a>
    </li>
    <li>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.facebook.com/stefan.wens'
      >
        <FaFacebook />
      </a>
    </li>
    <li>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='http://www.twitter.com/wensauer'
      >
        <FaTwitter />
      </a>
    </li>
    <li>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='http://www.github.com/sho3it'
      >
        <FaGithub />
      </a>
    </li>
    <li>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.xing.com/profile/Stefan_Wensauer'
      >
        <FaXing />
      </a>
    </li>
  </ul>
);

export default SocialLinks;
