import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import axios from "axios";
import ScrollableAnchor from "react-scrollable-anchor";
import { Transition } from "react-spring/renderprops";
import { InView } from "react-intersection-observer";

import "./App.scss";

const API_PATH = "https://shoit.uber.space/index.php";
class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: false,
      name: "",
      messageDoing: "",
      messageKnow: "",
      email: "",
      sent: false,
      error: null,
      jobTitle: "",
      workingWith: "",
      buttonText: <FormattedMessage id='contact.button' />
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
  }

  formSubmit(e) {
    let data = {
      name: this.state.name,
      email: this.state.email,
      message: `Hallo mein Name ist ${this.state.name}, folgende Nachricht:
      Job Titel: ${this.state.jobTitle} 
      Mit welchen Firmen gearbeitet wird: ${this.state.workingWith} 
      Was für ein Job es ist: ${this.state.messageDoing} 
      Was ich sonst noch wissen sollte: ${this.state.messageKnow}`
    };

    e.preventDefault();
    axios({
      method: "post",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data
    })
      .then(result => {
        if (result.data.sent) {
          this.setState({
            sent: result.data.sent,
            error: false
          });
          this.resetForm();
        } else {
          this.setState({ error: true });
          console.log(result);
        }
      })
      .catch(error => console.log(error.message));
  }

  resetForm() {
    this.setState({
      name: "",
      messageDoing: "",
      messageKnow: "",
      email: "",
      jobTitle: "",
      workingWith: ""
    });
  }

  render() {
    return (
      <ScrollableAnchor id={"section5"}>
        <div className='contact'>
          <InView
            onChange={
              this.state.view === false ? this.setState({ view: true }) : null
            }
          >
            <Transition
              items={this.state.view}
              config={{ duration: 4000 }}
              from={{ opacity: 0 }}
              enter={{ opacity: 1 }}
              leave={{ opacity: 0 }}
            >
              {show =>
                show &&
                (props => (
                  <div style={props} className='contact-header'>
                    <h2>
                      <FormattedMessage id='contact.title' />
                    </h2>
                  </div>
                ))
              }
            </Transition>

            <form className='contact-form' onSubmit={e => this.formSubmit(e)}>
              <div className='contact-item'>
                <label htmlFor='message-name'>
                  <FormattedMessage id='contact.whoAreYou' />
                  <p>
                    <FormattedMessage id='contact.whoAreYou.text' />
                  </p>
                </label>
                <input
                  onChange={e => this.setState({ name: e.target.value })}
                  name='name'
                  type='text'
                  value={this.state.name}
                />
              </div>

              <div className='contact-item'>
                <label htmlFor='message-name'>
                  <FormattedMessage id='contact.whereWork' />
                  <p>
                    <FormattedMessage id='contact.whereWork.text' />
                  </p>
                </label>
                <input
                  onChange={e => this.setState({ workingWith: e.target.value })}
                  name='name'
                  type='text'
                  value={this.state.workingWith}
                />
              </div>

              <div className='contact-item'>
                <label htmlFor='message-email'>
                  <FormattedMessage id='contact.yourEmail' />
                </label>
                <input
                  onChange={e => this.setState({ email: e.target.value })}
                  name='email'
                  type='email'
                  placeholder='...@email.com'
                  required
                  value={this.state.email}
                />
              </div>

              <div className='contact-item'>
                <label htmlFor='message-email'>
                  <FormattedMessage id='contact.whatJob' />
                  <p>
                    <FormattedMessage id='contact.whatJob.text' />
                  </p>
                </label>
                <input
                  onChange={e => this.setState({ jobTitle: e.target.value })}
                  name='jobTitle'
                  type='text'
                  required
                  value={this.state.jobTitle}
                />
              </div>

              <div className='contact-item'>
                <label htmlFor='message-input'>
                  <FormattedMessage id='contact.whatDoing' />
                  <p>
                    <FormattedMessage id='contact.whatDoing.text' />
                  </p>
                </label>
                <textarea
                  rows='8'
                  cols='50'
                  onChange={e =>
                    this.setState({ messageDoing: e.target.value })
                  }
                  name='message'
                  type='text'
                  placeholder='...'
                  value={this.state.messageDoing}
                  required
                />
              </div>

              <div className='contact-item'>
                <label htmlFor='message-input'>
                  <FormattedMessage id='contact.whatKnow' />
                  <p>
                    <FormattedMessage id='contact.whatKnow.text' />
                  </p>
                </label>
                <textarea
                  rows='8'
                  cols='50'
                  onChange={e => this.setState({ messageKnow: e.target.value })}
                  name='messageKnow'
                  type='text'
                  placeholder='...'
                  value={this.state.messageKnow}
                  required
                />
              </div>

              <div className='button--container'>
                <button className='button' type='submit'>
                  {this.state.buttonText}
                </button>
                {this.state.sent && (
                  <div className='send'>
                    <FormattedMessage id='contact.success' />
                  </div>
                )}
                {this.state.error && (
                  <div className='send'>
                    <FormattedMessage id='contact.error' />
                  </div>
                )}
              </div>
            </form>
          </InView>
        </div>
      </ScrollableAnchor>
    );
  }
}

export default Contact;
